































































































import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
import SmallCard from "@/components/_cards/small-card.vue";
import GenericTable from "@/components/listTable/generic-table.vue";
import UXTechnicianService from "@/services/ux/uxtechnicians";
import BarChart from "@/components/charts/bar-chart.vue";
import PieChart from "@/components/charts/pie-chart.vue";

let global = new GlobalServices();
let tech = new UXTechnicianService();
//Standard Components
import EVENTS from "@/constants/events";

export default {
  data() {
    return {
      listDataObj: {},
      EVENT_ACTION: {},
      actionId: null,
      id: null,
      parentActionId: null,
      moduleName: null,

      dropDownList: [
        {displayName: "Website", id: 1},
        {displayName: "Email", id: 2},
        {displayName: "Referral", id: 3},
      ],
      selecteRadio: "radio1",
      options: [
        {text: "This Week", value: "thisWeek"},
        {text: "Last Week", value: "lastWeek"},
        {text: "This Month", value: "thisMonth"},
        {text: "This Year", value: "thisYear"},
      ],
      mockStats: [
        {
          uniquieId: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Utilization Rate",
          displayNumber: "95%",
          queryStringParamater: "1",
          displayDescription: "description",
          color: "red",
        },
        {
          uniquieId: "2116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Goal: Total Hours",
          displayNumber: "255",
          queryStringParamater: "1",
          displayDescription: "description",
          color: "purple",
        },
        {
          uniquieId: "3116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "Actual: Hours Logged",
          displayNumber: "150",
          queryStringParamater: "1",
          displayDescription: "description",
          color: "red",
        },
        {
          uniquieId: "4116b3e6-cc45-4c2d-8772-d086db31b0ec",
          displayTitle: "To Travel Time",
          displayNumber: "16h 15m",
          queryStringParamater: "1",
          displayDescription: "description",
          color: "green",
        },
      ],
      MockChartData: {
          labels: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
          ],
          datasets: [
              {
                  label: 'All Technicians',
                  backgroundColor: '#f2555c    ',
                  data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                  borderRadius: 4,
              }
          ]
      },
      headers: [
        {
          id: 1,
          title: "Technician",
          columnName: "employeeName",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 2,
          title: "Time In",
          columnName: "timeIn",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 3,
          title: "Travel",
          columnName: "total",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 4,
          title: "Time Out",
          columnName: "timeOut",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 5,
          title: "Total",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 6,
          title: "Cost",
          columnName: "unitPrice",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
        {
          id: 7,
          title: "Billable",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
        },
      ],
      items: [],
      uxTechniciansList: [],
      technicianSelected: null,
      uxClientsList: [],
      clientSelected: null,
    };
  },
  components: {
    SmallCard,
    GenericTable,
    BarChart,
    PieChart,
  },
  methods: {
    async loadData() {
      this.id = 0;
      this.parentActionId = 23;
      this.moduleName = "Time Tracking";
      this.actionId = 86;
      this.uxTechniciansList = await tech.getTechnicians();
      this.uxClientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
      this.loadTable();
    },
    async loadTable() {
      //Set Header Properties to be passed in as prop values
      let param = {
        id: this.id,
        parentActionId: this.parentActionId,
        actionId: this.actionId,
      } as any;

      if (this.clientSelected) {
        param.clientId = this.clientSelected;
      }

      if (this.technicianSelected) {
        param.technicianId = this.technicianSelected;
      }
      const results = await global.getDataByActionId(param, true);
      this.listDataObj = results.table;
      // this.headers = this.listDataObj.header;
      this.items = this.listDataObj.data;
    },
  },
  async mounted() {
    await this.loadData();
    //Reload Data every 5 seconds
    this.timer = setInterval(async () => {
      await this.loadTable();
    }, 5000);
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = false;
  },
  created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;
  },
};
